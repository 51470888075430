for (const element of document.querySelectorAll('.slider-logo')) {
    const swiper = new Swiper('.slider-logo.swiper', {
        autoplay: {
            delay: 2500,
        },
        speed: 1000,
        centeredSlides: false,

        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },

        breakpoints: {
            1: {
                grid: {
                    rows: 2,
                    fill: 'row',
                },
                slidesPerView: 3,
                slidesPerGroup: 6,
            },
            1024: {
                grid: {
                    rows: 1,
                    fill: 'row',
                },
                slidesPerView: 6,
                slidesPerGroup: 6,
                spaceBetween: 30,
            }
        }
    });
}
