const aTags = document.getElementsByTagName('a')

for (const aTag of aTags) {
    if (!aTag.href.includes('#')) {
        continue
    }

    const targetElement = document.getElementById(aTag.href.substring(aTag.href.indexOf('#') + 1))

    if (!targetElement) {
        continue
    }

    aTag.addEventListener('click', (event) => {
        scrollToElement(event, targetElement, aTag)
    })
}

function scrollToElement(event, targetElement, sourceElement) {
    event.preventDefault()

    const url = sourceElement.href.substring(sourceElement.href.indexOf('#'))

    window.history.pushState({Title: document.title, Url: url}, document.title, url)

    const targetRect = targetElement.parentElement.getBoundingClientRect()
    const navigation = document.getElementsByTagName('nav')[0]
    const navigationRect = navigation.getBoundingClientRect()

    window.scrollTo({
        top: targetRect.top + window.scrollY - navigationRect.height,
        behavior: 'smooth'
    })
}