// open
const nav = document.querySelector('nav');
const burger = document.querySelectorAll('.navbar-burger');
const menu = document.querySelectorAll('.navbar-menu');
const close = document.querySelectorAll('.navbar-close');
const header = document.querySelector('#body-headerelements');
const bodyContent = document.querySelector('#body-content');
const theme = document.querySelector('html').className;

var isEmpty = header.innerHTML === "";

if (isEmpty == true) {
    bodyContent.classList.add('pt-75','lg:pt-150');
}

if (burger.length && menu.length) {
    for (var i = 0; i < burger.length; i++) {
        burger[i].addEventListener('click', function() {
            for (var j = 0; j < menu.length; j++) {
                menu[j].classList.toggle('hidden');
                burger[j].classList.toggle('hidden');
                close[j].classList.toggle('hidden');
            }
        });
    }
}

// close
if (close.length) {
    for (var i = 0; i < close.length; i++) {
        close[i].addEventListener('click', function() {
            for (var j = 0; j < menu.length; j++) {
                menu[j].classList.toggle('hidden');
                burger[j].classList.toggle('hidden');
                close[j].classList.toggle('hidden');
            }
        });
    }
}

// scrolling
var html = document.querySelector('html');
var windowPosition = window.scrollY

if (isEmpty == true) {
    nav.classList.add('lg:bg-dark');
} 
else {
    nav.classList.add('lg:bg-transparent')
    if (windowPosition > 149) {
        nav.classList.remove('lg:bg-transparent')
        nav.classList.add('lg:bg-dark');
    } 
}

document.addEventListener("scroll", (event) => {
    windowPosition = window.scrollY
    
    if (isEmpty == true) {
        return;
    } 
    
    else {
        if (windowPosition <= 149) {
            nav.classList.remove('lg:bg-dark');
            nav.classList.add('lg:bg-transparent')
        }

        if (windowPosition > 149) {
            nav.classList.remove('lg:bg-transparent')
            nav.classList.add('lg:bg-dark');
        } 
    }
});



