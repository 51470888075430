import '../Scss/includes.scss';
import Swiper from 'swiper/bundle';
import "@lottiefiles/lottie-player";

window.Swiper = Swiper;

window.addEventListener('load', e => {
    const modules = import.meta.glob([
        './Content/*.js',
        './Extensions/*/Index.js',
    ])

    for (const path in modules) {
        new Promise((_res, _rej) => modules[path]())
    }
})
