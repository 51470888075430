const video = document.querySelectorAll('.media-element .video-element');

if (video.length != 0) {
    video.forEach(item => {
        var overlay = item.querySelector('.hover-overlay');
        var overlayIcon = item.querySelector('.hover-overlay svg');
        var video = item.querySelector('video');
        var textBox = item.querySelector('.text-box');
        var textClose = item.querySelector('.text-box .close');

        overlay.addEventListener('click', function() {
            if( video.paused) {
                video.play();
                overlayIcon.classList.add('hidden');
            }
            else {
                video.pause()
                overlayIcon.classList.remove('hidden');
            }
        });

        if (textBox) {
            textClose.addEventListener('click', function() {
                textBox.classList.add('hidden')
            })
        }
    });
}