import {api} from '../../api';

export default class Filter {
    eID = 'smediaprojects-filter';
    filterButtons = document.querySelectorAll('.filter-button');
    uids = [];
    languageId = 0;
    filters = document.querySelectorAll('.filter');
    allButton = document.querySelector('.filter-button[data-uid="all"]')

    constructor () {
        this.uids = window['TX_SMEDIAPROJECTS_FILTER_UIDLIST'];
        this.languageId = window['TX_SMEDIAPROJECTS_FILTER_LANGUAGEID'];

        this.filterButtons.forEach((button) => {
            button.addEventListener('click', async e => {
                const { currentTarget: target } = e;
                const { dataset } = target;
                const { uid } = dataset;
                const allActive = this.allButton.classList.contains('active')

                this.filterButtons.forEach((button) => {
                    const buttonUid = button.dataset.uid

                    if(uid === 'all') {
                        if(button.classList.contains('active')) {
                            return
                        }

                        button.classList.add('active')

                        this.uids[buttonUid] = parseInt(buttonUid)
                        return
                    }

                    if(button.classList.contains('active') && buttonUid === uid && !allActive) {
                        return
                    }

                    if(buttonUid === uid) {
                        button.classList.add('active')
                        this.uids[buttonUid] = parseInt(buttonUid)
                        return
                    }

                    button.classList.remove('active')
                    delete this.uids[buttonUid];
                })

                if(uid === 'all') {
                    if(!target.classList.contains('active')) {
                        target.classList.add('active')
                    }
                    delete this.uids[uid]
                }

                const response = await api(this.eID, {
                    uidList: this.uids,
                    languageId: this.languageId
                });

                document.querySelector('#projects').outerHTML = await response.text();
            });
        });

        this.filters.forEach(filter => {
            filter.addEventListener('click', async e => {
                filter.classList.toggle('open');
            });
        });
    }
}
