const tabsElement = document.querySelectorAll('.tabs-element')
const loadedTabElements = []
const playingTabElements = []
let newTabsItem = '0'

if (tabsElement.length != 0) {
    window.addEventListener('scroll', event => {
        for (const element of tabsElement) {

            const rect = element.getBoundingClientRect()

            if(window.innerHeight/2 < rect.top) {
                return
            }

            const firstPlayer = element.querySelector('lottie-player')

            if(loadedTabElements.includes(firstPlayer)) {
                return
            }

            loadedTabElements.push(firstPlayer)

            firstPlayer.play()
        }
    })

    for (const element of tabsElement) {
        const tabs = element.querySelectorAll('.tab')
        const center = element.querySelector('.center-column')
        const left = element.querySelector('.left-column')
        const lottieElements = element.querySelectorAll('lottie-player')

        if (window.matchMedia("(max-width: 1024px)").matches) {
            const firstTab = left.querySelector('.tab')
            const firstId = firstTab.getAttribute('data-item')

            const activeElements = element.querySelectorAll('.active')
            const firstElements = element.querySelectorAll(`[data-item="${firstId}"]`)

            for(const activeElement of activeElements) {
                activeElement.classList.remove('active')
                activeElement.classList.add('hidden')
            }

            for(const firstElement of firstElements) {
                firstElement.classList.remove('hidden')
                firstElement.classList.add('active')
            }
        }

        for (const lottieElement of lottieElements) {
            lottieElement.addEventListener('complete', event => {onComplete(event, element)})
        }

        for (const tab of tabs) {
            tab.onclick = e => {
                const { currentTarget } = e
                const { item } = currentTarget.dataset

                if(playingTabElements.includes(element)) {
                    return
                }
                if (tab.classList.contains('active')) {
                    return
                }

                playingTabElements.push(element)

                newTabsItem = item
                
                const activeTab = currentTarget.parentNode.querySelector('.active')

                currentTarget.classList.add('active')

                const activeImageNode = center.querySelector('.image.active')

                const activeLottiePlayerNode = activeImageNode.querySelector('lottie-player')
                const unloadFrames = activeLottiePlayerNode.getAttribute('data-unload-frames').split(',')

                activeLottiePlayerNode.getLottie().firstFrame = 0
                activeLottiePlayerNode.getLottie().totalFrames = parseInt(unloadFrames[1])

                if(activeTab !== null) {
                    activeLottiePlayerNode.seek(
                        parseInt(unloadFrames[0])
                    )
                } else {
                    activeLottiePlayerNode.seek(
                        parseInt(unloadFrames[1]) - 1
                    )
                }

                if (window.matchMedia("(max-width: 1024px)").matches) {
                    activeLottiePlayerNode.seek(
                        parseInt(unloadFrames[1]) - 1
                    )
                }

                activeLottiePlayerNode.setSpeed(parseFloat(unloadFrames[2]))
                activeLottiePlayerNode.play()

                if(activeTab !== null) {
                    activeTab.classList.remove('active')
                }
            }
        }
    }
}

function onComplete(event, tabElement) {
    if(event.target.parentNode === null) {
        return
    }
    if(!event.target.parentNode.classList.contains('active')) {
        return
    }

    const center = tabElement.querySelector('.center-column')
    const right = tabElement.querySelector('.right-column')
    const left = tabElement.querySelector('.left-column')

    const imageNode = center.querySelector(`.image[data-item="${newTabsItem}"]`)
    const activeImageNode = center.querySelector('.image.active')

    const lottiePlayerNode = imageNode.querySelector('lottie-player')
    const activeLottiePlayerNode = activeImageNode.querySelector('lottie-player')

    if(activeLottiePlayerNode === lottiePlayerNode) {
        const index = playingTabElements.indexOf(tabElement)
        if (index !== -1) {
            playingTabElements.splice(index, 1)
        }
        return
    }

    const loadFrames = lottiePlayerNode.getAttribute('data-load-frames').split(',')

    lottiePlayerNode.getLottie().totalFrames = parseInt(loadFrames[1])
    lottiePlayerNode.setSpeed(parseFloat(loadFrames[2]))
    lottiePlayerNode.seek(parseInt(loadFrames[0]))

    if (window.matchMedia("(max-width: 1024px)").matches) {
        activeLottiePlayerNode.seek(
            parseInt(loadFrames[1]) - 1
        )
    }

    lottiePlayerNode.play()

    imageNode.classList.add('active')
    imageNode.classList.remove('hidden')
    activeImageNode.classList.remove('active')
    activeImageNode.classList.add('hidden')

    const textNode = right.querySelector(`.text[data-item="${newTabsItem}"]`)
    const textMobileNode = left.querySelector(`.mobile-text .text[data-item="${newTabsItem}"]`)
    const activeTextNode = right.querySelector('.text.active')
    const activeMobileTextNode = left.querySelector('.mobile-text .text.active')

    if(activeTextNode !== null) {
        activeTextNode.classList.remove('active')
        activeTextNode.classList.add('hidden')
    }

    if(activeMobileTextNode !== null) {
        activeMobileTextNode.classList.remove('active')
        activeMobileTextNode.classList.add('hidden')
    }

    if(textNode === null || textMobileNode === null) {
        return
    }

    textNode.classList.add('active')
    textNode.classList.remove('hidden')

    textMobileNode.classList.add('active')
    textMobileNode.classList.remove('hidden')
}
