for (const element of document.querySelectorAll('.dark-to-light')) {
    var scrollPosition = 0;
    var lastPosition = 0;
    var windowHeight = window.innerHeight;


    if(document.querySelectorAll('.dark-to-light')) {
        document.querySelectorAll('.dark-to-light').forEach(html => {
            let icons = html.querySelectorAll('.fill-arno-dark');
            icons.forEach(icon => {
                icon.classList.add('fill-arno-white');
            });
        }) 
    }

    window.addEventListener('scroll', () => {
        scrollPosition = window.pageYOffset;

        if (scrollPosition >= windowHeight && lastPosition < windowHeight) {
            if (document.querySelector('.dark-to-light .body').classList.contains('change-dark')) {
                document.querySelector('.dark-to-light .body').classList.remove('change-dark');
            }
            document.querySelector('.dark-to-light .body').classList.add('change-light');

        }

        if (scrollPosition <= windowHeight && lastPosition > windowHeight) {
            if (document.querySelector('.dark-to-light .body').classList.contains('change-light')) {
                document.querySelector('.dark-to-light .body').classList.remove('change-light');
            }
            document.querySelector('.dark-to-light').classList.add('change-dark');

            document.querySelectorAll('.fill-arno-dark').forEach(icon => {
                icon.classList.add('fill-arno-white');
            });


        }

        lastPosition = scrollPosition;
    })
}