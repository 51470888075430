import { gsap } from "gsap/dist/gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger"

const wrapper = document.querySelector('.slider-data .wrapper')
let tween = null

if(wrapper) {
    gsap.registerPlugin(ScrollTrigger)

    const slides = wrapper.getElementsByClassName('slide')
    tween = gsap.to(slides, {
        xPercent: -100 * (slides.length - 1),
        ease: "none",
        scrollTrigger: {
            trigger: ".slider-data",
            pin: true,
            pinSpacing: true,
            // start: '120% 95.33%',
            start: '180% 100%',
            scrub: 1,
            // base vertical scrolling on how wide the container is so it feels more natural.
            end: `+=${wrapper.scrollWidth/8}`,
        }
    });

    handleResize();

    window.addEventListener('resize', handleResize);
}

function handleResize() {
    if(window.innerWidth < 1024) {
        tween.scrollTrigger.disable(false)
        return
    }

    tween.scrollTrigger.enable(false, true);
}