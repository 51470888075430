const timelines = document.querySelectorAll('.timeline-element');

if (timelines.length !== 0) {
    for (const element of timelines) {
        const scroller = element.querySelector('.scroller');
        const scrollerHitbox = element.querySelector('.scroller-hitbox');
        const firstCircle = element.querySelector('.first-circle');
        const lastCircle = element.querySelector('.last-circle');

        let lastScrollTop = 0;
        let viewHeight = window.innerHeight;
        let isDown = false;
        let startpos;
        let dy;
        let state = null;
        let targetScrollTop = 0;
        let scrollAnimation = false;
        let isClickInScroller = false;

        scrollerHitbox.addEventListener('mousedown', function (e) {
            if (e.stopPropagation) e.stopPropagation();
            if (e.preventDefault) e.preventDefault();

            isDown = true;
            startpos = e.clientY;
            state = 'grabbed';
            element.style.cursor = 'grabbing';
            isClickInScroller = true;
        });

        const handleScroll = () => {
            let st = window.pageYOffset || document.documentElement.scrollTop;
            let lcTop = lastCircle.getBoundingClientRect().top;
            let fcTop = firstCircle.getBoundingClientRect().top;

            if (st > lastScrollTop) {
                // downscroll code

                if (fcTop <= viewHeight / 2 && scroller.classList.contains('absolute')) {
                    scroller.classList.remove('absolute');
                    scroller.classList.add('fixed');
                    scroller.style.top = viewHeight / 2 - 55 + 'px';
                }

                if (lcTop <= viewHeight / 2 && scroller.classList.contains('fixed')) {
                    scroller.classList.remove('fixed');
                    scroller.classList.add('absolute');
                    scroller.style.top = lastCircle.parentElement.parentElement.offsetTop + 'px';
                }

            } else if (st < lastScrollTop) {
                // upscroll code

                if (lcTop >= viewHeight / 2 && scroller.classList.contains('absolute')) {
                    scroller.classList.remove('absolute');
                    scroller.classList.add('fixed');
                    scroller.style.top = viewHeight / 2 - 55 + 'px';
                }

                if (fcTop >= viewHeight / 2 && scroller.classList.contains('fixed')) {
                    scroller.classList.remove('fixed');
                    scroller.classList.add('absolute');
                    scroller.style.top = firstCircle.parentElement.parentElement.offsetTop + 'px';
                }
            }
            lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
        };

        const handleMouseRelease = () => {
            if (!isDown || state !== 'grabbed' || !isClickInScroller) return;

            isDown = false;
            isClickInScroller = false;
            state = null;
            element.style.cursor = 'default';

            // Calculate the target scroll position based on last pointer position
            targetScrollTop = window.scrollY + dy * 0.4; // Adjust the factor as needed

            // Initiate smooth scrolling animation
            scrollAnimation = true;
            requestAnimationFrame(() => {});
        };

        const handleMouseMove = (e) => {
            if (!isDown || state !== 'grabbed') return;

            dy = e.clientY - startpos;

            const firstCircleRect = firstCircle.getBoundingClientRect();
            const lastCircleRect = lastCircle.getBoundingClientRect();
            const cursorY = e.clientY;
            const isOverFirstCircle = cursorY <= firstCircleRect.top;
            const isOverLastCircle = cursorY >= lastCircleRect.bottom;

            // Check if the cursor is over the first or last circle when scroller is absolute
            if (scroller.classList.contains('absolute') && ((dy < 0 && isOverFirstCircle) || (dy > 0 && isOverLastCircle))) {
                return;
            }

            // Use a similar approach as in your provided code
            const scrollAmount = dy * 0.1; // Adjust this number to control the scroll speed

            window.scrollBy({
                top: scrollAmount,
                behavior: 'auto'
            });

            // Continue scrolling if still grabbed
            if (isDown && state === 'grabbed') {
                // This empty function is used to trigger a new animation frame
                requestAnimationFrame(() => {});
            }
        };

        document.addEventListener('scroll', handleScroll);
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseleave', handleMouseRelease);
        document.addEventListener('mouseup', handleMouseRelease);
    }
}