const textSwiper = document.querySelectorAll('.slider-text');

if (textSwiper.length != 0) {
    const swiper = new Swiper('.slider-text .swiper', {
        loop: true,
        autoplay: {
            delay: 5000,
        },
        speed: 1000,
        slidesPerView: 1,
        centeredSlides: false,

        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
    });
}