const fullsizeSwiper = document.querySelectorAll('.slider-fullsize');

if (fullsizeSwiper.length != 0) {
    for (const swiper of fullsizeSwiper) {
        if (swiper.classList.contains('navtype_dots')) {
            const swiper = new Swiper('.slider-fullsize.swiper', {
                loop: true,
                autoplay: {
                    delay: 7500,
                },
                speed: 1000,
                slidesPerView: 1,
                centeredSlides: false,
                spaceBetween: 30,
        
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                },
            });
        }
        else {
            const swiper = new Swiper('.slider-fullsize.swiper', {
                loop: true,
                speed: 1000,
                slidesPerView: 1,
                centeredSlides: false,
                spaceBetween: 30,
        
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        }
    }
}

