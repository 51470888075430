const lists = document.querySelectorAll('.frame li')


if (lists.length != 0) {
    for (const list of lists) {
        const spanElement = list.getElementsByTagName('span')[0]

        if(!spanElement) {
            continue
        }

        const spanColorMain = spanElement.classList.contains('!text-arno-main');
        const spanColorBlack = spanElement.classList.contains('!text-arno-black');
        const spanColorGrey = spanElement.classList.contains('!text-arno-grey');

        if (spanColorMain) {
            list.classList.add('!text-arno-main')
        }
        if (spanColorBlack) {
            list.classList.add('!text-arno-black')
        }
        if (spanColorGrey) {
            list.classList.add('!text-arno-grey')
        }
    }
}