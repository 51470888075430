const blockquote = document.querySelectorAll('.blockquote-swiper');

if (blockquote.length != 0) {
    const blockquoteSwiper = new Swiper('.blockquote-swiper', {
        loop: true,
        navigation: {
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
        },
    });
}