for (const element of document.querySelectorAll('#anker')) {
    let delay = 0;
    let windowPos = 0;
    
    if (window.innerWidth <= 1023) {
        delay = 2000;
    }
    else {
        delay = 500;
    }

    window.addEventListener("resize", () => {
        if (window.innerWidth <= 1023) {
            delay = 2000;
        }
        else {
            delay = 500;
        }
    })
    
    document.addEventListener("scroll", (event) => {
        if (window.scrollY < 500) {
            element.classList.remove('fade-in');
            element.classList.add('hidden');
        }

        else {
            if (element.classList.contains('hidden')) {
                element.classList.remove('hidden');
            }
    
            if (element.classList.contains('fade-in')) {
                element.classList.remove('fade-in');
            }

            element.classList.add('hidden');
    
            setTimeout(function() {
                if (window.scrollY > 500) {
                    element.classList.remove('hidden');
                    element.classList.add('fade-in');
                }
            }, delay);
        }
       
    })

    element.addEventListener("click", () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    })
}
