const dataCookieElement = document.querySelectorAll('.data-cookie-element');

if (dataCookieElement.length != 0) {
    for (const element of dataCookieElement) {
        const texts = element.querySelectorAll('.texts .item');
        const sectors = element.querySelectorAll('.circle .sector');

        for (const sector of sectors) {
            sector.addEventListener('click', function() {
                const sectorPosition = sector.getAttribute('data-position');

                for (const text of texts) {
                    const textPosition = text.getAttribute('data-position');
                    
                    if (textPosition == sectorPosition) {
                        text.classList.remove('hidden');
                        text.classList.add('active');
                    }

                    else {
                        if (text.classList.contains('active')) {
                            text.classList.remove('active');
                            text.classList.add('hidden')
                            for (const sector of sectors) {
                                sector.classList.remove('active');
                            }
                        }
                    }
                }

                sector.classList.add('active');
            })
        }
    }
}
