export const ajax = (typeNum, body, method = 'POST') => {
    return (async () => {
        const url = `/?type=${typeNum}`;

        return await fetch(url, {
            method: method,
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            }
        });
    })();
};
