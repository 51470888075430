const service = document.querySelectorAll('.service-list');

if (service.length != 0) {
    const teasers = document.querySelectorAll('.service-teasers .teaser-item');
    const items =document.querySelectorAll('.service-list .service-list-item');

    teasers.forEach(teaser => {
        const teaserLink = teaser.querySelector('.link')
        const teaserId = teaser.getAttribute('data-uid')

        teaserLink.addEventListener('click', function() {
            items.forEach(item => {
                const itemId = item.getAttribute('data-uid')

                if (itemId === teaserId) {
                    const navHeight = document.querySelector('nav').offsetHeight;
                    const bodyRect = document.body.getBoundingClientRect();
                    const itemRect = item.getBoundingClientRect();
                    const offset = itemRect.top - bodyRect.top - navHeight;

                    window.scrollTo({top: offset, behavior: 'smooth'});
                }
            })
        });
    });
};