window.addEventListener('CookiebotOnAccept', (event) => {
    for(const key in Cookiebot.consent) {
        enableCookieElements(key)
    }
})

function enableCookieElements(cookieName) {
    if(Cookiebot.consent[cookieName] !== true) {
        return
    }

    const elements = document.querySelectorAll(`[data-cookie="${cookieName}"]`)

    for(const element of elements) {
        const parent = element.parentElement

        if(element.tagName.toLowerCase() === 'script') {
            const newScriptElement = document.createElement('script')
            newScriptElement.async = true

            newScriptElement.innerHTML = element.innerHTML

            parent.appendChild(newScriptElement)

            element.remove()
        }
    }
}