const interactiveElement = document.querySelectorAll('.interactive-image-element');

if (interactiveElement.length != 0) {
    for (const element of interactiveElement) {
        const circles = element.querySelectorAll('circle');
        const crosses = element.querySelectorAll('path');
        const texts = element.querySelectorAll('.text');
        let index = 0;

        for(const text of texts) {
            index ++;
            text.setAttribute('data-uid', index);

            if (index == 1) {
                text.classList.add('active')
            }
        }

        index = 0;

        for(const circle of circles) {
            index ++;
            circle.setAttribute('data-uid', index);

            circle.addEventListener('mouseover', function(){
                circle.nextElementSibling.classList.add('hover');
                circle.classList.add('hover');
            });
            circle.addEventListener('mouseout', function(){
                circle.nextElementSibling.classList.remove('hover');
                circle.classList.remove('hover');
            });
            circle.addEventListener('click', function() {
                var circleIndex = circle.getAttribute('data-uid');

                for(const text of texts) {
                    var textIndex = text.getAttribute('data-uid');
                    
                    text.classList.remove('active');
                    
                    if(circleIndex == textIndex) {
                        text.classList.toggle('active');
                    }
                }
            });
        }

        index = 0;

        for(const cross of crosses) {
            index ++;
            cross.setAttribute('data-uid', index);

            cross.addEventListener('mouseover', function(){
                cross.previousElementSibling.classList.add('hover');
                cross.classList.add('hover');
            });
            cross.addEventListener('mouseout', function(){
                cross.previousElementSibling.classList.remove('hover');
                cross.classList.remove('hover');
            });
            cross.addEventListener('click', function() {
                var crossIndex = cross.getAttribute('data-uid');

                for(const text of texts) {
                    var textIndex = text.getAttribute('data-uid');
                    
                    text.classList.remove('active');
                    
                    if(crossIndex == textIndex) {
                        text.classList.toggle('active');
                    }
                }
            });
        }
    }
}
