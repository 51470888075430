export const api = (eID, body) => {
    return (async () => {
        const url = `/?eID=${eID}`;

        return await fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
        });
    })();
};
