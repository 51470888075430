import {ajax} from "../../ajax";

const locations = document.querySelectorAll('.location-list .location');

let loaded = false;

if (locations.length !== 0) {
    const loadLocation = async (uid, href, popstate = false) => {
        const node = document.querySelector(`.location-list .location[data-uid="${uid}"]`);
        node.parentNode.querySelector('.active').classList.remove('active');
        node.classList.add('active');
        const [addressesNode, contactpersonsNode] = [
            document.querySelector('#tx-smedialocations-addresses'),
            document.querySelector('#tx-smedialocations-contactpersons'),
        ];
        const [addressMobil, contactMobil] = [
            document.querySelector('.address-mobil'),
            document.querySelector('.contact-mobil'),
        ];
        const response = await ajax('1687338858', { uid: uid });
        const json = await response.json();

        if (!popstate) {
            history.pushState({
                uid: uid,
                href: href,
            }, '', `${href}`);
        }
        const {addresses, contactpersons} = json;
        // addressesNode.outerHTML = addresses;
        // contactpersonsNode.outerHTML = contactpersons;
        // if (document.documentElement.clientWidth < 720) {
        //     addressMobil.outerHTML = addresses;
        //     contactMobil.outerHTML = contactpersons;
        //     console.log(addresses, contactpersons);
            // document.querySelectorAll('.location').forEach(link => {
            //     link.addEventListener('click', () => {
            //         console.log(link.nextElementSibling)
                    
            //     })
            // })
            // addressesNode.outerHTML = null;
            // contactpersonsNode.outerHTML = null;
        // } else {
            addressesNode.outerHTML = addresses;
            contactpersonsNode.outerHTML = contactpersons;
        // };
    };

    if (document.documentElement.clientWidth > 850) {
        
            for (const location of locations) {
                location.onclick = async e => {
                    if (e.currentTarget.classList.contains('bypass-ajax')) {
                        return;
                    }
        
                    e.preventDefault();
        
                    if (e.currentTarget.classList.contains('active')) {
                        return false;
                    }
        
                    const {uid} = e.currentTarget.dataset;
                    let base_url = window.location.origin;
                    const {href} = e.currentTarget;
                    let fixed_href =  href.replace(base_url,'');
                    loadLocation(uid, fixed_href);
                };
            }
    }


if (document.documentElement.clientWidth < 850) {
    document.querySelector('.location.active').nextElementSibling.appendChild(document.querySelector('.destop'));
    locations.forEach((link) => {

        link.addEventListener('click', (e) => {
                e.preventDefault();
            for(let i = 0; i < locations.length; i++) {
                locations[i].classList.remove('active');
            }

            link.classList.add('active');
            link.nextElementSibling.appendChild(document.querySelector('.destop'));
                const {uid} = e.currentTarget.dataset;
                let base_url = window.location.origin;
                const {href} = e.currentTarget;
                let fixed_href =  href.replace(base_url,'');
                loadLocation(uid, fixed_href);
        })
    })
}



    window.addEventListener('popstate', async e => {
        const {uid, pathSegment, href} = e.state;
        loadLocation(uid, href, true);
    });

    if (loaded === true) {
        const activeNode = document.querySelector('.location-list .location.active');
        let node = document.querySelector('.location-list .location:first-child');

        if (activeNode) {
            node = activeNode;
        }
        const {uid} = node.dataset;

        let base_url = window.location.origin;
        const {old_href} = node.currentTarget();
        let href =  old_href.replace(base_url,'');

        history.pushState({
            uid: uid,
            href: href,
        }, '', `${href}`);
    }
}



window.addEventListener('DOMContentLoaded', (event) => {
    loaded === true;
});